import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import theme from "../style/theme";

import Nav from "../component/common/Nav";
export default function Product() {
  const [systemElement, setSystemElement] = useState(null);
  const [serviceElement, setServiceElement] = useState(null);
  const [productElement, setProductElement] = useState(null);
  const [name, setName] = useState(null);
  const { navValue } = useParams();

  useLayoutEffect(() => {
    setSystemElement(document.getElementById("system"));
    setServiceElement(document.getElementById("service"));
    setProductElement(document.getElementById("product"));
    setName(navValue)
  }, [navValue]);

  useEffect(() => {
      document.body.style.overflow = 'auto';
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });

  useEffect(() => {
    if (name === "Product") {
      if (productElement) {
        productElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (name === "Service") {
      if (serviceElement) {
        serviceElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (name === "System") {
      if (systemElement) {
        systemElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    setName(null);
  }, [name !== null]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav select={"제품소개"} textColor={true} logo={'../images/logo.svg'} bg={true}/>
        <Banner id="system">
          <div className="center" >
            <div className="banner_text">
              <div className="left_box">
                <h2>EAZY-i</h2>
                <h1>
                  건물 에너지&실내환경
                  <br />
                  통합관리 서비스
                </h1>
                <p>
                  누구나 <strong>편리하게</strong>, 에너지{" "}
                  <strong>낭비없이</strong>, 공간을 <strong>쾌적하게</strong>
                </p>
              </div>
              <div className="right_box">
                <div className="border"></div>
                <h3>
                  Building Energy & Indoor EnvironmentIntegrated management
                  service
                </h3>
              </div>
            </div>
          </div>
        </Banner>
        <ContainerWrap>
          <SystemWrap>
            <div className="title">
              <p>system</p>
              <h2>시스템 구성도</h2>
            </div>
            <div className="img_box">
              <img
                src="/images/product/main_img.png"
                alt="시스템 이미지"
                width="100%"
              />
            </div>
          </SystemWrap>
        </ContainerWrap>
        <ContainerWrap>
          <div className="title"  id="service">
            <p>service</p>
            <h2>서비스</h2>
          </div>
        </ContainerWrap>
        <ServiceWrap>
          <ContainerWrap>
            <ul className="service_wrap"  id="service">
              <li>
                <div>
                  <h2 className="sub_title">에너지 & 공기질 관리를 한번에 !</h2>
                  <h3 className="name">EAZY-i 서비스</h3>
                  <p className="content">
                    냉난방기와 실내환경 상태 모니터링/진단, 필터 <br />
                    세척 알림 및 연동장치 제어용 앱 서비스
                  </p>
                </div>
                <ul className="qr_wrap">
                  <li>
                    <div className="qr_img">
                      <img src="/images/product/qr_ios.png" />
                    </div>
                    <a href="">
                      <img src="/images/product/ios_btn.png" />
                    </a>
                  </li>
                  <li>
                    <div className="qr_img">
                      <img src="/images/product/qr_and.png" />
                    </div>
                    <a href="">
                      <img src="/images/product/and_btn.png" />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="app_wrap">
                <div className="app_img">
                  <img
                    src="/images/product/app1.png"
                    width="100%"
                    alt="모바일 화면"
                  />
                </div>
                <div className="app_img">
                  <img
                    src="/images/product/app2.png"
                    width="100%"
                    alt="모바일 화면"
                  />
                </div>
                <div className="app_img">
                  <img
                    src="/images/product/app3.png"
                    width="100%"
                    alt="모바일 화면"
                  />
                </div>
              </li>
            </ul>
          </ContainerWrap>
        </ServiceWrap>
        <ContainerWrap>
        <div className="title2">
            <p>product</p>
            <h2>제품</h2>
          </div>
          <ProductInfo>
            <div className="product_img_wrap" id="product">
              <div className="product_img">
                <img src="/images/product/product1_1.png" width="100%" />
              </div>
              <div className="product_img">
                <img src="/images/product/product1_2.png" width="100%" />
              </div>
            </div>
            <div className="product_info">
              <h2 className="sub_title">배터리 교체는 이제 그만 !</h2>
              <h3 className="name">EAZY-i 센서</h3>
              <p className="content">
                풍력발전으로 전기에너지를 생성, 소비함으로써 외부에서 전원공급
                없이도 냉난방기 운전상태 및 필터 오염도 진단용 데이터를 수집하는
                센서 장치
              </p>
              <div className="box_text">
                <p>
                  제품 크기 : 지름 260mm, 높이 80mm
                  <br />
                  측정 항목 : 냉난방기 흡입공기 풍속, 온도, 습도
                  <br />
                  통신 방식 : WiFi 2.4GHz, BLE
                </p>
              </div>
            </div>
          </ProductInfo>
        </ContainerWrap>
        <Background>
          <ContainerWrap>
            <ProductInfo>
              <div className="product_info">
                <h2 className="sub_title">스마트 제어로 쉽고 편리하게 !</h2>
                <h3 className="name">EAZY-i 허브</h3>
                <p className="content">
                  실내 온/습도 및 공기질 데이터를 수집하여 분석 결과에 따라
                  냉난방기, 공기청정기를 자동 제어하는 IoT 장치
                </p>
                <div className="box_text">
                  <p>
                    제품 크기 : 70mm x 70mm x 70mm
                    <br />
                    측정 항목 : 온도, 습도, 미세먼지(PM2.5, PM10), TVOC, CO2
                    <br />
                    통신 방식 : WiFi 2.4GHz, BLE
                  </p>
                </div>
              </div>
              <div className="product_img_wrap">
                <div className="product_img">
                  <img src="/images/product/product2_2.png" width="100%" />
                </div>
                <div className="product_img">
                  <img src="/images/product/product2_1.png" width="80%" />
                </div>
              </div>
            </ProductInfo>
          </ContainerWrap>
        </Background>
      </ThemeProvider>
    </>
  );
}

const ContainerWrap = styled(Box)`
  width: 128rem;
  margin: 0 auto;
  .title {
    margin-bottom: 8rem;
    p {
      color: rgba(59, 59, 59, 0.8);
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
    }
    h2 {
      color: #3b3b3b;
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      margin-top: 1rem;
    }
  }
  .title2 {
    p {
      margin-top: 13rem;
      color: rgba(59, 59, 59, 0.8);
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
    }
    h2 {
      color: #3b3b3b;
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      margin-top: 1rem;
    }
  }
  .sub_title {
    font-size: 3.2rem;
    color: #3676d2;
    font-weight: bold;
    line-height: 150%;
  }
  .name {
    font-size: 2.8rem;
    color: #3b3b3b;
    line-height: 150%;
    font-weight: bold;
    margin: 2rem 0 1rem 0;
  }
  .content {
    color: #3b3b3b;
    line-height: rgba(59, 59, 59, 0.8);
    font-size: 2rem;
    line-height: 150%;
  }

  @media ${() => theme.device.desktop} {
    width: 100%;
  }
  @media ${() => theme.device.mobile} {
    .title {
      margin-bottom: 5rem;
      h2 {
        font-size: 2.5rem;
      }
    }
    .sub_title {
      font-size: 2.5rem;
    }
    .name {
      font-size: 2.2rem;
    }
    .content {
      font-size: 1.8rem;
    }
  }
`;

const Banner = styled(Box)`
  margin-top: 8rem;
  background-image: url(../../images/product/banner.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 62.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .center {
    position: relative;
    width: 128rem;
    margin: 0 auto;
  }
  .banner_text {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: -140px;
    left: 0;
    .left_box {
      width: 50%;
      background-color: #3676d2;
      padding: 5rem 5rem 8rem 5rem;
      box-sizing: border-box;
      h2 {
        font-size: 3.2rem;
        color: #fff;
        font-weight: bold;
        line-height: 150%;
        margin-bottom: 2rem;
      }
      h1 {
        font-size: 4rem;
        color: #fff;
        font-weight: bold;
        line-height: 150%;
        margin-bottom: 2rem;
      }
      p {
        font-size: 2.4rem;
        color: #fff;
        line-height: 150%;
        strong {
          font-weight: bold;
        }
      }
    }
    .right_box {
      background-color: #f0f6ff;
      padding: 5rem;
      width: 50%;
      box-sizing: border-box;
      .border {
        width: 50px;
        height: 5px;
        background-color: #3676d2;
        margin-bottom: 5rem;
      }
      h3 {
        font-size: 3rem;
        font-weight: bold;
        color: #3676d2;
        line-height: 150%;
      }
    }
  }

  @media ${() => theme.device.desktop} {
    .center {
      width: 90%;
    }
  }
  @media ${() => theme.device.tablet} {
    .banner_text {
      flex-direction: column;
      .left_box {
        width: 100%;
        padding: 3rem;
        h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
        h2 {
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }
        p {
          font-size: 2rem;
        }
      }
      .right_box {
        padding: 3rem;
        width: 100%;
        .border {
          margin-bottom: 2rem;
        }
        h3 {
          font-size: 2.5rem;
        }
      }
    }
  }
`;

const SystemWrap = styled(Box)`
  padding-top: 25rem;
  padding-bottom: 10rem;
  @media ${() => theme.device.desktop} {
    .img_box {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    padding-top: 20rem;
    padding-bottom: 8rem;
  }
`;

const ServiceWrap = styled(Box)`
  background-image: url(../../images/product/Rectangle.png);
  background-position: bottom;
  background-color: cover;
  background-repeat: no-repeat;
  .service_wrap {
    width: 128rem;
    margin: 0 auto;
    z-index: 98;

    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      width: 40%;
      .qr_wrap {
        display: flex;
        align-items: center;
        margin-top: 10rem;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 2.5rem;
          .qr_img {
            background-color: rgba(255, 255, 255, 0.6);
            padding: 2rem;
            border-radius: 8px;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
    li:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .app_wrap {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .app_img {
        width: 32%;
      }
    }
  }
  @media ${() => theme.device.desktop} {
    .service_wrap {
      width: 90%;
    }
  }
  @media ${() => theme.device.tablet} {
    .service_wrap {
      flex-direction: column;
      li {
        width: 100%;
        .qr_wrap {
          margin-top: 0;
          li {
            .qr_img {
              padding: 0;
            }
          }
        }
      }
      .app_wrap {
        width: 100%;
        margin-top: 5rem;
      }
      li:first-of-type {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    padding: 0;
    .service_wrap {
      li:first-of-type {
        flex-direction: column;
        div:first-of-type:not(.qr_img) {
          width: 100%;
        }
      }
      li {
        .qr_wrap {
          margin-top: 5rem;
          width: 100%;
          margin-bottom: 5rem;
          li {
            margin: 0;
          }
        }
      }
    }
  }
`;

const ProductInfo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10rem 0;
  .product_img_wrap {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .product_img {
      width: 48%;
    }
  }

  .product_info {
    width: 43%;
    .box_text {
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 2.5rem;
      margin-top: 3rem;
      p {
        font-size: 2rem;
        line-height: 150%;
        color: #3b3b3b;
      }
    }
  }
  @media ${() => theme.device.tablet} {
    padding: 8rem 0;
    flex-direction: column-reverse;
    .product_img_wrap {
      width: 90%;
      margin-top: 5rem;
    }
    .product_info {
      width: 90%;
      .box_text {
        p {
          font-size: 1.8rem;
        }
      }
    }
  }
`;

const Background = styled(Box)`
  background-color: #f9f9f9;
  @media ${() => theme.device.tablet} {
    & div:first-of-type {
      flex-direction: column !important;
    }
  }
`;
