import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import useStore from '../../common/store';

export default function LoginPage() {
  const { signin, user } = useStore();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async() => {
    try {
      const response = await signin(username, password);
      if (response.status === 200) {
        console.log("로그인 성공");
        navigate(`/`);
        
      } else {
        console.log("로그인 실패");
      }
    } catch (error) {
      
    }
  };

  return (
    <LoginPageContainer>
      <Logo>
        <img src="../images/logo.svg" alt="logo" />
      </Logo>
      <LoginForm>
        <Input
          type="text"
          placeholder="아이디"
          value={username}
          onChange={handleUsernameChange}
        />
        <Input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={handlePasswordChange}
        />
      </LoginForm>
      <LoginButton onClick={handleLogin}>로그인</LoginButton>
    </LoginPageContainer>
  );
}

const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Logo = styled.div`
  margin-bottom: 30px;
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 300px;
  height: 40px;
  margin: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const LoginButton = styled.button`
  width: 300px;
  height: 40px;
  background-color: #00367B;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; /* 수평 및 수직 중앙 정렬 스타일 */

  &:hover {
    background-color: #2557a7;
  }
`;
