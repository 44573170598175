import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import theme from "../style/theme";
import Nav from "../component/common/Nav";
import { SectionsContainer, Section } from "react-fullpage";

export default function Business() {
  const [smartElement, setSmartElement] = useState(null);
  const [energyElement, setEnergyElement] = useState(null);
  const [dataElement, setDataElement] = useState(null);
  const [name, setName] = useState(null);
  const { navValue } = useParams();

  useLayoutEffect(() => {
    setSmartElement(document.getElementById("sec1"));
    setEnergyElement(document.getElementById("sec2"));
    setDataElement(document.getElementById("sec3"));
    setName(navValue);
  }, [navValue]);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });

  useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflowY = "hidden";
  }, []);

  let options = {
    anchors: ["sectionOne", "sectionTwo", "sectionThree"],
  };

  // useEffect(() => {
  //   console.log(name);
  //   if (name === "Data") {
  //     if (dataElement) {
  //       window.history.pushState(null, null,`#sectionThree`)
  //       // dataElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (name === "Energy") {
  //     if (energyElement) {
  //       window.history.pushState(null, null,`#sectionTwo`)
  //       // energyElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (name === "Smart") {
  //     if (smartElement) {
  //       window.history.pushState(null, null,`#sectionOne `)
  //       // smartElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  //   setName(null);
  // }, [name !== null]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav
          select={"사업영역"}
          textColor={true}
          logo={"../images/logo.svg"}
          bg={true}
        />
        <SectionsContainer {...options}>
          <StyledSection1 id="sec1">
            <div className="content">
              <div className="padding">
                <h2>BUSINESS 01</h2>
                <h1>스마트 에너지 관리 플랫폼</h1>
                <p>
                  건물 내부에 설치된 센서와 연결된 <strong>IoT 플랫폼</strong>을
                  개발하여 <br />
                  스마트 에너지 관리 솔루션을 제공합니다.
                  <br />
                  이를 통해 건물의 전력 소비, 냉난방 시스템, 조명 등을
                  모니터링하고 <br />
                  최적화할 수 있습니다. 또한, 사용자들은 원격으로 에너지
                  사용량을 <br />
                  모니터링하고 제어함으로써 <strong>에너지 비용을 절감</strong>
                  할 수 있습니다.
                  <br />
                </p>
                <StyledButton variant="contained">플랫폼 바로가기</StyledButton>
              </div>
            </div>
          </StyledSection1>
          <StyledSection2>
            <div className="content" id="sec2">
              <div className="padding">
                <h2>BUSINESS 02</h2>
                <h1>에너지 & 실내환경 모니터링 및 제어</h1>
                <p>
                  건물 내외부의 환경 요소를 측정하고 모니터링, 분석, <br />
                  제어하는 <strong>IoT 기반의 솔루션</strong>을 제공합니다.
                  <br />
                  실내 공기질, 온도, 습도 등과 같은 데이터를 실시간으로 수집하여{" "}
                  <br />
                  환경 상태를 파악하고 이상 상황 발생 시{" "}
                  <strong>알림 제공과 스마트제어</strong>를 <br />
                  통해 건물 내부 환경의 질과 거주자의 편안함을 증진시킬 수
                  있습니다.
                  <br />
                </p>
                <StyledButton variant="contained">EAZY-i 바로가기</StyledButton>
              </div>
            </div>
          </StyledSection2>
          <StyledSection3>
            <div className="content" id="sec3">
              <div className="padding">
                <h2>BUSINESS 03</h2>
                <h1>데이터 분석 및 예측</h1>
                <p>
                  수집된 에너지와 실내환경 데이터를 기반으로{" "}
                  <strong>AI 알고리즘</strong>으로 분석한
                  <br />
                  해당 공간, 설비의 상태진단 결과에 따라 냉난방, 공기청정, 조명
                  시스템 등이 <br />
                  자동으로 제어되고 이상 감지 시 알림 서비스,{" "}
                  <strong>맞춤형 운영보고서</strong>를 제공합니다. <br />
                  이를 통해 사용자는 건물 에너지 효율성과 실내 쾌적성을 확보하고
                  유지관리 <br />
                  시간과 비용을 줄일 수 있습니다.
                </p>
              </div>
            </div>
          </StyledSection3>
        </SectionsContainer>
      </ThemeProvider>
    </>
  );
}

const StyledSection1 = styled(Section)`
  height: 100vh;
  background-image: url(../../images/business/img1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
    width: 100%;
    .padding {
      padding-right: 15%;
    }
    h2 {
      font-size: 1.8rem;
      color: #fff;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 4.2rem;
      color: #fff;
      font-weight: bold;
      line-height: 150%;
      text-align: left;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 300;
      line-height: 150%;
      text-align: left;
      margin-bottom: 50px;
      strong {
        font-weight: 600;
      }
    }
  }
  @media ${() => theme.device.desktop} {
    .content {
      p br {
        display: none;
      }
      justify-content: center;
      .padding {
        padding: 0 10%;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    .content {
      h1 {
        font-size: 3.5rem;
      }
      p {
        font-size: 1.6rem;
        margin-bottom: 30px;
      }
    }
  }
`;

const StyledSection2 = styled(Section)`
  height: 100vh;
  background-image: url(../../images/business/img2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
    .padding {
      padding-left: 15%;
    }
    h2 {
      font-size: 1.8rem;
      color: #fff;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 4.2rem;
      color: #fff;
      font-weight: bold;
      line-height: 150%;
      text-align: left;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 300;
      line-height: 150%;
      text-align: left;
      margin-bottom: 50px;
      strong {
        font-weight: 600;
      }
    }
  }
  @media ${() => theme.device.desktop} {
    .content {
      p br {
        display: none;
      }
      justify-content: center;
      .padding {
        padding: 0 10%;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    .content {
      h1 {
        font-size: 3.5rem;
      }
      p {
        font-size: 1.6rem;
        margin-bottom: 30px;
      }
    }
  }
`;

const StyledSection3 = styled(Section)`
  height: 100vh;
  background-image: url(../../images/business/img3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
    width: 100%;
    .padding {
      padding-right: 15%;
    }
    h2 {
      font-size: 1.8rem;
      color: #fff;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 4.2rem;
      color: #fff;
      font-weight: bold;
      line-height: 150%;
      text-align: left;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 300;
      line-height: 150%;
      text-align: left;
      strong {
        font-weight: 600;
      }
    }
  }
  @media ${() => theme.device.desktop} {
    .content {
      p br {
        display: none;
      }
      justify-content: center;
      .padding {
        padding: 0 10%;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    .content {
      h1 {
        font-size: 3.5rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  font-size: 2rem;
  border-radius: 100px;
  padding: 12px 50px;
  box-shadow: none;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    font-size: 1.6rem;
  }
`;
