import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import theme from "../style/theme";
import Nav from "../component/common/Nav";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

export default function Company(route) {
  const [aboutElement, setAboutElement] = useState(null);
  const [visionElement, setVisionElement] = useState(null);
  const [infoElement, setInfoElement] = useState(null);
  const [name, setName] = useState(null);
  const { navValue } = useParams();

  useLayoutEffect(()=>{
    setAboutElement(document.getElementById("about"))
    setVisionElement(document.getElementById("vision"))
    setInfoElement(document.getElementById("info"))
    setName(navValue)
  },[navValue])

  useEffect(() => {
      document.body.style.overflow = 'auto';
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });

  useEffect(() => {
    if (name === "Info") {
      if (infoElement) {
        infoElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (name === "Vision") {
      if (visionElement) {
        visionElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (name === "About") {
      if (aboutElement) {
        aboutElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    setName(null);
  }, [name !== null]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav select={"회사소개"} textColor={true} logo={'../images/logo.svg'} bg={true}/>
        <CompanyWrap>
          <div className="main_banner" id="about">
            <h2>ABOUT US</h2>
            <h1>EAZY INNOVATION</h1>
            <p>
              건물 에너지 · 환경 · 탄소 분야의 IoT 플랫폼 서비스를 제공하는
              기업으로
              <br />
              사람에 대한 믿음, 기술에 대한 믿음 그리고 기업에 대한 믿음과 함께
              <br />
              지속적으로 성장하는 혁신기업이 되겠습니다
            </p>
          </div>
          <InfoBox>
            <div className="container">
              <div className="dp-flex space-between info">
                <div className="img_box">
                  <img src="/images/company/img1.png" width="100%" />
                </div>
                <div className="content">
                  <img src="/images/company/text_icon1.svg" />
                  <h2>
                    우리와 함께 스마트하고, <br />
                    <strong>지속 가능한 미래</strong>를 만들어요{" "}
                  </h2>
                  <p>
                    이지이노베이션은 혁신적인 기술과 창의적인 아이디어를 통해{" "}
                    <br />
                    여러 분야의 고객을 대상으로 에너지 · 환경 · 탄소 관리의{" "}
                    <br />
                    효율성을 높이는 시스템과 다양하고 전문적인 정보를 <br />
                    제공받을 수 있는 솔루션을 제공합니다
                  </p>
                </div>
              </div>
            </div>
            <div className="background">
              <div className="container dp-flex space-between info">
                <div className="content">
                  <img src="/images/company/text_icon2.svg" />
                  <h2>
                    <strong>지능화된 에너지 세상</strong>을 만들어 갑니다
                  </h2>
                  <p>
                    우리는 에너지의 중요성과 IoT의 혁신적인 힘의 결합을 통해
                    <br />
                    세계의 에너지 사용 방식을 변화시키려는 목표를 가지고 있으며,{" "}
                    <br />
                    단순히 에너지 절약이 아닌, 에너지의 지능화를 통한 <br />
                    지속 가능한 세상 구축을 추구합니다
                  </p>
                </div>
                <div className="img_box">
                  <img src="/images/company/img2.png" width="100%" />
                </div>
              </div>
            </div>
          </InfoBox>
          <Vision>
            <div className="container" id="vision">
              <h2>VISION</h2>
              <ul className="vision_list">
                <li>
                  <div className="text">
                    <h3>VISION 1</h3>
                    <h4>Honesty and Trust</h4>
                    <h2>정직과 신뢰</h2>
                  </div>
                  <div className="icon_box">
                    <img src="/images/company/honesty.svg" />
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h3>VISION 2</h3>
                    <h4>Challenge and Fun</h4>
                    <h2>도전과 즐거움</h2>
                  </div>
                  <div className="icon_box">
                    <img src="/images/company/goal.svg" />
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h3>VISION 3</h3>
                    <h4>Professionalism and Autonomy</h4>
                    <h2>전문성과 자율성</h2>
                  </div>
                  <div className="icon_box">
                    <img src="/images/company/manager.svg" />
                  </div>
                </li>
              </ul>
            </div>
          </Vision>
          <CompanyInfo>
            <div className="container" >
              <div className="content dp-flex space-between">
                <div id="info">
                  <h3>COMPANY INFO</h3>
                  <ul className="info_list">
                    <li>
                      <div className="title">
                        <LocationOnIcon />
                        <h4>찾아오시는 길</h4>
                      </div>
                      <p>
                        서울특별시 노원구 공릉로 232
                        <br />
                        서울과학기술대학교 창업보육센터 104호
                      </p>
                    </li>
                    <li>
                      <div className="title">
                        <EmailIcon />
                        <h4>문의</h4>
                      </div>
                      <p>eazy-i@eazy-i.com</p>
                    </li>
                  </ul>
                </div>
                <div className="map">
                  <img src="/images/company/map.png" width="100%" />
                </div>
              </div>
            </div>
          </CompanyInfo>
        </CompanyWrap>
      </ThemeProvider>
    </>
  );
}

const CompanyWrap = styled(Box)`
  .container {
    width: 128rem;
    margin: 0 auto;
  }
  .dp-flex {
    display: flex;
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
  .main_banner {
    background-image: url(../../images/company/banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 13rem 0;
    margin-top: 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: #fff;
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 150%;
      text-align: center;
    }
    h1 {
      color: #fff;
      font-weight: bold;
      line-height: 120%;
      font-size: 5rem;
      text-align: center;
    }
    p {
      color: #fff;
      line-height: 150%;
      font-size: 2rem;
      text-align: center;
      margin-top: 3rem;
    }
  }
  @media ${() => theme.device.desktop} {
    .container {
      width: 100%;
    }
  }
  @media ${() => theme.device.tablet} {
    .main_banner {
      padding: 10rem 2rem;
      h1 {
        font-size: 3.8rem;
      }
      h2 {
        font-size: 2rem;
      }
      p {
        font-size: 1.6rem;
      }
      p br {
        display: none;
      }
    }
  }
`;

const InfoBox = styled(Box)`
  padding: 10rem 0;
  & > div:first-of-type {
    margin-bottom: 10rem;
  }
  .img_box {
    width: 45%;
  }
  .background {
    background-color: #f9f9f9;
    padding: 10rem 0;
  }
  .content {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
      font-size: 3.2rem;
      color: #3b3b3b;
      font-weight: bold;
      line-height: 130%;
      margin: 2rem 0 3rem 0;
      strong {
        color: #3676d2;
      }
    }
    p {
      color: #3b3b3b;
      font-size: 2rem;
      line-height: 150%;
      color: #3b3b3b;
    }
  }
  @media ${() => theme.device.tablet} {
    & > div:first-of-type {
      margin-bottom: 8rem;
    }
    & .background .info {
      flex-direction: column;
    }
    .info {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center !important;
    }
    .img_box {
      width: 90%;
      margin-top: 3rem;
    }
    .content {
      width: 90%;
      p {
        br {
          display: none;
        }
      }
    }
    .background {
      padding: 7rem 0 8rem 0;
    }
  }
`;

const Vision = styled(Box)`
  padding-bottom: 10rem;
  h2 {
    font-size: 3.2rem;
    color: #3b3b3b;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
    margin-bottom: 5rem;
  }
  .vision_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 5rem;
      box-sizing: border-box;
      width: 32%;
      height: 38rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .text {
        h3 {
          font-size: 1.4rem;
          color: #3676d2;
          font-weight: bold;
          padding: 5px 10px;
          border-radius: 100px;
          background-color: #f0f6ff;
          width: fit-content;
          margin-bottom: 1.5rem;
        }
        h4 {
          font-size: 1.6rem;
          color: rgba(0, 0, 0, 0.6);
          line-height: 150%;
        }
        h2 {
          text-align: left;
          font-size: 3.5rem;
        }
      }
      .icon_box {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
  @media ${() => theme.device.desktop} {
    .vision_list {
      flex-direction: column;
      li {
        width: 90%;
        margin-bottom: 2rem;
        padding: 3rem;
        height: auto;
        .text {
          h2 {
            font-size: 2.5rem;
          }
          h4 {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`;

const CompanyInfo = styled(Box)`
  background-image: url(../../images/company/banner2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0;
  .content {
    h3 {
      font-size: 3.2rem;
      color: #fff;
      font-weight: bold;
      line-height: 150%;
    }
    li {
      margin: 2rem 0;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        svg {
          color: #fff;
          margin-right: 1rem;
        }
        h4 {
          font-size: 2rem;
          color: #fff;
          line-height: 150%;
          font-weight: 600;
        }
      }
      p {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.6);
        line-height: 150%;
      }
    }
    li:last-of-type {
      margin: 0;
    }
  }
  .map {
    width: 48%;
  }
  @media ${() => theme.device.tablet} {
    .content {
      div:first-of-type {
        width: 90%;
      }
    }
    .dp-flex {
      flex-direction: column;
    }
    .map {
      width: 90%;
      margin-top: 2rem;
    }
  }
`;
