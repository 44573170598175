
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {createTheme,Divider,Icon,ThemeProvider} from '@mui/material';
import { Box, IconButton,Button } from "@mui/material";
import theme from "../style/theme";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import 'swiper/css/bundle';
import Nav from "../component/common/Nav";
SwiperCore.use([Pagination, Navigation, Autoplay]);

// eslint-disable-next-line import/no-anonymous-default-export
export default function Main(){
    const theme = createTheme({
        typography:{
            fontFamily : "Pretendard"
        },
        palette: {
            primary: {
              main: "#3676D2",
            },
         },
    })

    return(
        <>
            <ThemeProvider theme={theme}>
                <Nav textColor={false} logo={'../images/w-logo.svg'} bg={false}/>
                <MainWrap>
                    <Swiper
                        slidesPerView={1}
                        // navigation
                        pagination
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                          }}
                        >
                        <SwiperSlide>
                            <div className="main_img" id="main1">
                                <h1><strong>IoT 기반 에너지 · 환경 · 탄소</strong> <br/>
                                통합관리 플랫폼</h1>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="main_img_wrap">
                                <div className="main_img_box" id="main_img_box1"></div>
                                <div className="main_img_box" id="main_img_box2"></div>
                                <div className="main_img_box" id="main_img_box3"></div>

                                <div className="content">
                                    <img src="/images/text_logo.svg"/>
                                    <h2>EAZY-i : 건물 에너지 & 실내환경 통합관리 시스템</h2>
                                    <ul className="info_box">
                                        <li>
                                            <h3>01</h3>
                                            <p>saving energy</p>
                                            <h2>에너지 절약</h2>
                                        </li>
                                        <li>
                                            <h3>02</h3>
                                            <p>comfortable space</p>
                                            <h2>쾌적한 공간</h2>
                                        </li>
                                        <li>
                                            <h3>03</h3>
                                            <p>user convenience</p>
                                            <h2>사용 편의성</h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </MainWrap>
            </ThemeProvider>
        </>
    )
}

const MainWrap =styled(Box)`
    .main_img{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;  
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
            font-size: 6.5rem;
            color: #fff;
            line-height: 150%;
            text-align: center;
            strong{
                font-weight: bold;
            }
        }
    }
    .main_img_wrap{
        display: flex;
        align-items: center;
        position: relative;
        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 50%;
            img{
                margin-bottom: 12rem;
            }
            h2{
                font-size: 3.2rem;
                color: #fff;
                text-align: center;
            }
            .info_box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4rem;
                width: 100%;
                li{
                    background-color: rgba(54,118,210,.6);
                    border-radius: 8px;
                    padding: 3rem;
                    box-sizing: border-box;
                    width: 32%;
                    h3{
                        font-size: 25px;
                        font-weight: bold;
                        color: #fff;
                        margin-bottom: 3rem;
                    }
                    p{
                        margin-bottom: 1rem;
                        font-size: 2rem;
                        color: #fff;
                        font-weight: 300;
                    }
                    h2{
                        font-weight: bold;
                        color: #fff;
                        text-align: left;
                        font-size: 3rem;
                    }
                }
            }
        }
    }
    .main_img_box{
        width: 35%;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;  
    }
    #main1{
        background-image: url(../../images/main/main1.png);
    }
    #main_img_box1{
        background-image: url(../../images/main/main2_1.png);
    }
    #main_img_box2{
        background-image: url(../../images/main/main2_2.png);
    }
    #main_img_box3{
        background-image: url(../../images/main/main2_3.png);
    }
    .swiper-pagination{
        bottom: 50px;
        .swiper-pagination-bullet{
            background-color: #fff;
            opacity: 1;
        }
        .swiper-pagination-bullet-active{
            background-color: #3676D2;
        }
    }
    @media ${() => theme.device.desktop2} {
        .main_img_wrap{
            .content{
                width: 90%;
            }
        }
    }
    @media ${() => theme.device.mobile} {
       .main_img{
          h1{
            font-size: 5rem;
            padding: 0 2rem;
          }
       }
       .main_img_wrap{
            flex-direction: column;
            .content{
                img{
                    width: 70%;
                    margin-bottom: 5rem;
                }
                h2{
                    font-size: 2.5rem;
                    line-height: 150%;
                }
            }
       }
       .main_img_box{
        width: 100%;
        height: 33.3vh;
       }
    }

    @media ${() => theme.device.mobile2} {
        .main_img{
            h1{
                font-size: 3.6rem;
            }
        }
        .main_img_wrap{
            .content{
                img{
                    width: 80%;
                    margin-bottom: 3rem;
                }
                .info_box{
                    flex-direction: column;
                    margin-top: 3rem;
                    li{
                        width: 100%;
                        padding: 2rem;
                        margin-bottom: 2rem;
                        h2{
                            font-size: 2.2rem;
                        }
                    }
                    li:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    
`;