import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStore from "../../common/store";
import Nav from "../../component/common/Nav";
import Banner from "../../component/notice/Banner";
import PostFooter from "../../component/notice/footer";

import theme from "../../style/theme";

export default function PostDetail() {
  const { getFaqDetail } = useStore();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });

  useEffect(() => {
    const id = localStorage.getItem("faq_id");
    const fetchData = async () => {
      try {
        const response = await getFaqDetail(id);
        console.log(response);
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav select={"고객서비스"} />
        <NoticeWrap>
          <Banner menu={"FAQ"} title={"자주 묻는 질문"} />
          <PostDetailWrap>
            <ContainerWrap>
            <h3>자주 묻는 질문</h3>
              {data !== undefined && data !== null && (
                <>
                  <InfoTable>
                    <div className="grid-item">글번호</div>
                    <div className="grid-item">{data.id}</div>
                    <div className="grid-item">작성자</div>
                    <div className="grid-item">관리자</div>
                    <div className="grid-item">작성 날짜</div>
                    <div className="grid-item">{data.creationdate}</div>
                    <div className="grid-item">조회수</div>
                    <div className="grid-item">{data.number_of_views}</div>
                  </InfoTable>
                  <InfoTable2>
                    <div className="grid-item">제목</div>
                    <div className="grid-item">{data.title}</div>
                  </InfoTable2>
                  <PostContent>
                    <p>{data.content}</p>
                  </PostContent>
                </>
              )}
              <PostFooter />
            </ContainerWrap>
          </PostDetailWrap>
        </NoticeWrap>
      </ThemeProvider>
    </>
  );
}

const NoticeWrap = styled(Box)`
  h3 {
    color: #3b3b3b;
    font-size: 2rem;
    font-weight: bold;
    line-height: 150%;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid #00367b;
  }
  .pagination_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 0 0;
    button {
      font-size: 1.4rem;
    }
  }
  @media ${() => theme.device.desktop} {
    .scroll {
      overflow-y: scroll;
    }
  }
`;

const ContainerWrap = styled(Box)`
  width: 128rem;
  margin: 0 auto;
  padding: 5rem 0;
  @media ${() => theme.device.desktop} {
    width: 90%;
  }
`;

const PostDetailWrap = styled(Box)`
  h3 {
    color: #3b3b3b;
    font-size: 2rem;
    font-weight: bold;
    line-height: 150%;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid #00367b;
  }
`;

const PostHeader = styled(Box)`
  margin-top: 2rem;
`;

const PostContent = styled(Box)`
  min-height: 500px;
  margin-top: 2rem;
  p {
    font-size: 1.6rem;
    color: #3b3b3b;
    line-height: 150%;
  }
  border-bottom: 2px solid #00367b;
`;

const InfoTable = styled(Box)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);

  .grid-item {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000; /* Optional border for visual separation */
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
  }

  .grid-item:nth-of-type(odd) {
    background-color: #dcdcde;
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
    font-weight: bold;
  }
`;

const InfoTable2 = styled(Box)`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;

  .grid-item {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000; /* Optional border for visual separation */
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
  }

  .grid-item:nth-of-type(odd) {
    background-color: #dcdcde;
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
    font-weight: bold;
  }
`;
