import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    background-color: #00367b;
    color: #fff;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    border: none;
    margin-left: 1rem;
    cursor: pointer;
  }
  .w-btn {
    position: relative;
    border: none;
    display: inline-block;
    padding: 10px 25px;
    font-weight: 600;
    transition: 0.25s;
  }
  
  .w-btn:hover {
    cursor: pointer;
  }

  .w-btn:active {
    background-color: #3d6ba6;
    color: white;
  }

`;

export default function PostFooter() {
  // 글 수정 및 삭제 버튼의 클릭 이벤트 핸들러를 추가할 수 있습니다.
  const handleEditClick = () => {
    // 글 수정 기능을 구현
  };

  const handleDeleteClick = () => {
    // 글 삭제 기능을 구현
  };

  return (
    <Footer>
      <button className='w-btn ' onClick={handleEditClick}>수정</button>
      <button className='w-btn'  onClick={handleDeleteClick}>삭제</button>
    </Footer>
  );
}