import { create } from "zustand";
import axiosInstance from "../util/axiosInstance";

const useStore = create(() => ({
  user: null,
  notice_id: null,
  error: null,
  loading: false,

  setNotice_id: (id) => {
    localStorage.setItem("notice_id", id);
  },

  setFaq_id: (id) => {
    localStorage.setItem("faq_id", id);
  },

  signin: async (id, passwd) => {
    const params = {
      id: id,
      passwd: passwd,
    };

    try {
      const response = await axiosInstance.get("/api/login", { params });
      localStorage.setItem("user", response.data.status);
      return response;
    } catch (error) {
      return error;
    }
  },

  _incrementViewCount : async (count, id, type) => {
    try {
      const response = await axiosInstance.put(`/api/view_count?count=${count}&id=${id}&type=${type}`);
      console.log(response.data);
    } catch (error) {
      return error;
    }
  },

  getNotices: async (page) => {
    try {
      const response = await axiosInstance.get(`/api/notices?pageNum=${page}`);
      console.log(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },

  getNoticeDetail: async (id) => {
    try {
      const response = await axiosInstance.get(`/api/notice_detail?id=${id}`);

      return response.data;
    } catch (error) {
      return error;
    }
  },

  getFaqs: async (page) => {
    try {
      const response = await axiosInstance.get(`/api/faqs?pageNum=${page}`);
      console.log(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },

  getFaqDetail: async (id) => {
    try {
      const response = await axiosInstance.get(`/api/faq_detail?id=${id}`);

      return response.data;
    } catch (error) {
      return error;
    }
  },
}));

export default useStore;
