import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
// import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import theme from "../../style/theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
// import useStore from '../../common/store';

export default function Nav({ select, logo, textColor, bg }) {
  const navigate = useNavigate();
  // const { user } = useStore();
  const company = ["About", "Vision", "Info"];
  const business = ["Smart", "Energy", "Data"];
  const product = ["System", "Service", "Product"];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const Close = () => {
    setAnchorEl(null);
  };
  const handleClose = (name) => {
    if (company.includes(name)) {
      navigate(`/company/${name}`);
    }
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const Close2 = () => {
    setAnchorEl2(null);
  };
  const handleClose2 = (name) => {
    if (business.includes(name)) {
      navigate(`/business/Data`);
    }
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const Close3 = () => {
    setAnchorEl3(null);
  };
  const handleClose3 = (name) => {
    if (product.includes(name)) {
      navigate(`/product/${name}`);
    }
    setAnchorEl3(null);
  };

  const [anchorEl4, setAnchorEl4] = useState(null);
  const open4 = Boolean(anchorEl4);
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const Close4 = () => {
    setAnchorEl4(null);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const [openNav, setOpenNav] = useState(false);

  const openNavClick = () => {
    setOpenNav(!openNav);
  };

  const [moOpen, setMoOpen] = useState(false);

  const handleClickMo = () => {
    setMoOpen(!moOpen);
  };

  const [moOpen2, setMoOpen2] = useState(false);

  const handleClickMo2 = () => {
    setMoOpen2(!moOpen2);
  };

  const [moOpen3, setMoOpen3] = useState(false);

  const handleClickMo3 = () => {
    setMoOpen3(!moOpen3);
  };

  const [moOpen4, setMoOpen4] = useState(false);

  const handleClickMo4 = () => {
    setMoOpen4(!moOpen4);
  };

  return (
    <Navrap textColor={textColor ? true : false} bg={bg ? false  : true}>
      <PC>
        <nav className="navbar">
          <div className="nav-center">
            <div className="nav-header">
              <Link href="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <ul className="nav-links">
              <li>
                {select == "회사소개" ? (
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    className="select"
                    onMouseOver={handleClick}
                    onBlur={Close}
                  >
                    회사소개
                  </Button>
                ) : (
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    textColor={true}
                    onMouseOver={handleClick}
                    onBlur={Close}
                  >
                    회사소개
                  </Button>
                )}
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  onClose={Close}
                >
                  <StyledMenuItem onClick={(e) => handleClose("About")}>
                    ABOUT US
                  </StyledMenuItem>
                  <StyledMenuItem onClick={(e) => handleClose("Vision")}>
                    VISION
                  </StyledMenuItem>
                  <StyledMenuItem onClick={(e) => handleClose("Info")}>
                    COMPANY INFO
                  </StyledMenuItem>
                </StyledMenu>
              </li>
              <li>
                {select == "사업영역" ? (
                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    className="select"
                    textColor={true}
                    onMouseOver={handleClick2}
                    onBlur={Close2}
                  >
                    사업영역
                  </Button>
                ) : (
                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    textColor={true}
                    onMouseOver={handleClick2}
                    onBlur={Close2}
                  >
                    사업영역
                  </Button>
                )}
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  onClose={Close2}
                >
                  <Link>
                    <StyledMenuItem onClick={(e) => handleClose2("Smart")}>
                      스마트 에너지 관리 플랫폼
                    </StyledMenuItem>
                  </Link>
                  <Link>
                    <StyledMenuItem onClick={(e) => handleClose2("Energy")}>
                      에너지 · 실내환경 모니터링 및 제어
                    </StyledMenuItem>
                  </Link>
                  <Link>
                    <StyledMenuItem onClick={(e) => handleClose2("Data")}>
                      데이터 분석 및 예측
                    </StyledMenuItem>
                  </Link>
                </StyledMenu>
              </li>
              <li>
                {select == "제품소개" ? (
                  <Button
                    id="basic-button"
                    aria-controls={open3 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open3 ? "true" : undefined}
                    onClick={handleClick3}
                    className="select"
                    textColor={true}
                    onMouseOver={handleClick3}
                    onBlur={Close3}
                  >
                    제품소개
                  </Button>
                ) : (
                  <Button
                    id="basic-button"
                    aria-controls={open3 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open3 ? "true" : undefined}
                    onClick={handleClick3}
                    textColor={true}
                    onMouseOver={handleClick3}
                    onBlur={Close3}
                  >
                    제품소개
                  </Button>
                )}
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl3}
                  open={open3}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  onClose={Close3}
                >
                  <StyledMenuItem onClick={(e) => handleClose3("System")}>
                    EAZY-i 시스템
                  </StyledMenuItem>
                  <StyledMenuItem onClick={(e) => handleClose3("Service")}>
                    EAZY-i 서비스
                  </StyledMenuItem>
                  <StyledMenuItem onClick={(e) => handleClose3("Product")}>
                    EAZY-i 제품 구성
                  </StyledMenuItem>
                </StyledMenu>
              </li>
              {/* <li>
                {select == "고객서비스" ? (
                  <Button
                    id="basic-button"
                    aria-controls={open4 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open4 ? "true" : undefined}
                    onClick={handleClick4}
                    className="select"
                  >
                    고객서비스
                  </Button>
                ) : (
                  <Button
                    id="basic-button"
                    aria-controls={open4 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open4 ? "true" : undefined}
                    onClick={handleClick4}
                  >
                    고객서비스
                  </Button>
                )}
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl4}
                  open={open4}
                  onClose={handleClose4}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link href="/notice">
                    <StyledMenuItem onClick={handleClose4}>
                      공지사항
                    </StyledMenuItem>
                  </Link>
                  <Link href="/notice/faq">
                    <StyledMenuItem onClick={handleClose4}>FAQ</StyledMenuItem>
                  </Link>
                  <Link href="/notice/contact">
                    <StyledMenuItem onClick={handleClose4}>
                      문의하기
                    </StyledMenuItem>
                  </Link>
                </StyledMenu>
              </li> */}
              {/* <li className="icon_wrap">
                <IconButton>
                  <LanguageIcon />
                </IconButton>
                <IconButton>
                  <MenuIcon />
                </IconButton>
              </li> */}
            </ul>
          </div>
        </nav>
      </PC>
      <Mobile>
        <div className="nav-wrap">
          <StyledList
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="Nav"
            aria-labelledby="nested-list-subheader"
          >
            <div className="dp-flex mobileNav">
              <div className="logo">
                <a href="/">
                  <img src="../images/logo.svg"></img>
                </a>
              </div>
              <div className="dp-flex">
                <IconButton>
                  <LanguageIcon />
                </IconButton>
                <MenuBtn onClick={openNavClick}>
                  <MenuIcon />
                </MenuBtn>
              </div>
            </div>
            <StyledCollapse in={openNav} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ mt: 2 }}>
                {select == "회사소개" ? (
                  <StyledListItemButton
                    className="select"
                    onClick={handleClickMo}
                  >
                    <ListItemText primary="회사소개" />
                    {moOpen ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                ) : (
                  <StyledListItemButton onClick={handleClickMo}>
                    <ListItemText primary="회사소개" />
                    {moOpen ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                )}
                <Collapse in={moOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link href="/company">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="ABOUT US" />
                      </ListItemButton>
                    </Link>
                    <Link href="/company">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="VISION" />
                      </ListItemButton>
                    </Link>
                    <Link href="/company">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="COMPANY INFO" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
              </List>
              <List component="div" disablePadding sx={{ mt: 2 }}>
                {select == "사업영역" ? (
                  <StyledListItemButton
                    className="select"
                    onClick={handleClickMo2}
                  >
                    <ListItemText primary="사업영역" />
                    {moOpen2 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                ) : (
                  <StyledListItemButton onClick={handleClickMo2}>
                    <ListItemText primary="사업영역" />
                    {moOpen2 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                )}
                <Collapse in={moOpen2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link href="/business">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="스마트 에너지 관리 플랫폼" />
                      </ListItemButton>
                    </Link>
                    <Link href="/business">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="에너지 · 실내환경 모니터링 및 제어  " />
                      </ListItemButton>
                    </Link>
                    <Link href="/business">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="데이터 분석 및 예측" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
              </List>
              <List component="div" disablePadding sx={{ mt: 2 }}>
                {select == "제품소개" ? (
                  <StyledListItemButton
                    className="select"
                    onClick={handleClickMo3}
                  >
                    <ListItemText primary="제품소개" />
                    {moOpen3 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                ) : (
                  <StyledListItemButton onClick={handleClickMo3}>
                    <ListItemText primary="제품소개" />
                    {moOpen3 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                )}
                <Collapse in={moOpen3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link href="/product">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="EAZY-i 시스템" />
                      </ListItemButton>
                    </Link>
                    <Link href="/product">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="EAZY-i 서비스" />
                      </ListItemButton>
                    </Link>
                    <Link href="/product">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="EAZY-i 제품 구성" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
              </List>
              <List component="div" disablePadding sx={{ mt: 2 }}>
                {select == "고객서비스" ? (
                  <StyledListItemButton
                    className="select"
                    onClick={handleClickMo4}
                  >
                    <ListItemText primary="고객서비스" />
                    {moOpen4 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                ) : (
                  <StyledListItemButton onClick={handleClickMo4}>
                    <ListItemText primary="고객서비스" />
                    {moOpen4 ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItemButton>
                )}
                <Collapse in={moOpen4} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link href="/notice">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="공지사항" />
                      </ListItemButton>
                    </Link>
                    <Link href="/notice/faq">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="FAQ" />
                      </ListItemButton>
                    </Link>
                    <Link href="/notice/contact">
                      <ListItemButton sx={{ pl: 4 }}>
                        <StyledListItemText primary="문의하기" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
              </List>
            </StyledCollapse>
          </StyledList>
        </div>
      </Mobile>
    </Navrap>
  );
}

const PC = styled(Box)`
  @media ${() => theme.device.mobile} {
    display: none;
  }
`;
const Mobile = styled(Box)`
  display: none;
  @media ${() => theme.device.mobile} {
    display: block;
  }
`;

const Navrap = styled(Box)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  .dp-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar {
    background-color: ${(props) => (props.bg ? "none" : "#fff")};
    padding: 20px 5%;
    .nav-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .nav-links {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      .select {
        color: #3676d2;
        font-weight: bold;
      }
      button {
        font-size: 16px;
        color: ${(props) => (props.textColor ? "#3b3b3b" : "#fff")};
        font-weight: 400;
      }
      svg {
        color: #3b3b3b;
        width: 2.5rem;
        height: 2.5rem;
      }
      button:first-of-type {
        margin-right: 1rem;
      }
    }
    li:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)``;

const StyledMenu = styled(Menu)`
  a {
    text-decoration: none !important;
  }
  li {
    font-size: 14px;
    color: #3b3b3b;
    text-decoration: none;
    span {
      text-decoration: none !important;
    }
  }
  li:hover {
    background-color: #f0f6ff;
    font-weight: bold;
    color: #3676d2;
  }
`;

const StyledList = styled(List)`
  position: relative;
  box-sizing: border-box;
  padding: 2rem 2rem 2rem 2.5rem;
  background-color: #fff;
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobileNav {
    align-items: center;
    justify-content: space-between;
    svg {
      color: #3b3b3b;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  @media ${() => theme.device.mobile} {
    .mobileNav {
      button:last-of-type {
        padding: 0 1rem;
        min-width: auto;
      }
      .logo {
        img {
          width: 80%;
        }
      }
    }
  }
`;

const MenuBtn = styled(Button)`
  min-width: max-content;
  svg {
    width: 3rem;
    height: 3rem;
    color: #3b3b3b;
  }
`;

const StyledCollapse = styled(Collapse)`
  position: fixed;
  top: 6rem;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  padding-bottom: 2rem;
  .select {
    span {
      color: #3676d2;
      font-weight: bold;
    }
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  color: #3b3b3b;
  padding: 0 3rem;

  span {
    font-size: 1.4rem;
    color: #3b3b3b;
    font-weight: 600;
  }
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const StyledListItemText = styled(ListItemText)`
  span {
    font-size: 1.4rem;
    color: #3b3b3b;
    font-weight: 500;
  }
`;
