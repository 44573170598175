import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyle from './style/GlobalStyle';
import Main from "./pages/Main";
import LoginPage from "./pages/admin/LoginPage";
import Company from "./pages/Company";
import Business from "./pages/Business";
import Product from "./pages/Product";
import Notice from "./pages/notice/Notice";
import FAQ from "./pages/notice/FAQ";
import ContactUS from "./pages/notice/ContactUs";
import DatailView_noti from "./pages/notice/DatailView_noti";
import DatailView_faq from "./pages/notice/DatailView_faq";


function App() {
  return (
    <>
      <GlobalStyle/>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/admin/Login" element={ <LoginPage /> }></Route>
          <Route path="/company/:navValue" element={ <Company /> }></Route>
          <Route path="/business/:navValue" element={ <Business /> }></Route>
          <Route path="/product/:navValue" element={ <Product /> }></Route>
          <Route path="/notice" element={ <Notice /> }></Route>
          <Route path="/notice/faq" element={ <FAQ /> }></Route>
          <Route path="/notice/contact" element={ <ContactUS /> }></Route>
          <Route path="/notice/detail_noti" element={ <DatailView_noti /> }></Route>
          <Route path="/notice/detail_faq" element={ <DatailView_faq /> }></Route>
        </Routes>
    </>
  );
}

export default App;
