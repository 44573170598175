import React, { useState } from "react";
import styled from "@emotion/styled";
import {createTheme,Divider,Icon,ThemeProvider} from '@mui/material';
import { Box, IconButton,Button } from "@mui/material";
import theme from "../../style/theme";
export default function Banner({title, menu}){
    return(
        <>
            <BannerWrap>
                <div className="container">
                    <h2>{menu}</h2>
                    <h1>{title}</h1>
                </div>
            </BannerWrap>
        </>
    )
}

const BannerWrap = styled(Box)`
    background-image: url(../../images/notice/banner.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10rem 0;
    width: 100%;
    margin-top: 8rem;
    .container{
        width: 128rem;
        margin: 0 auto;
    }
    h2{
        font-size: 2.5rem;
        color: #fff;
        line-height: 150%;
    }
    h1{
        font-size: 4rem;
        color: #fff;
        font-weight: bold;
        line-height: 150%;
    }
    @media ${() => theme.device.desktop} {
        .container{
            width: 90%;
        }
    }
    @media ${() => theme.device.tablet} {
        h2{
            font-size: 2.2rem;
        }
        h1{
            font-size: 3.5rem;
        }
    }
    @media ${() => theme.device.mobile} {
        padding: 8rem 0;
        h2{
            font-size: 2rem;
        }
        h1{
            font-size: 3.2rem;
        }
    }
`;