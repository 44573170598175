import React, { useState } from "react";
import styled from "@emotion/styled";
import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import theme from "../../style/theme";
import TextField from "@mui/material/TextField";
import Nav from "../../component/common/Nav";
import Banner from "../../component/notice/Banner";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function ContactUS() {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    title: "",
  });
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClick = async () => {
    console.log(formData);
    alert("문의내용이 정상적으로 전달되었습니다.");
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav select={"고객서비스"} />
        <NoticeWrap>
          <Banner menu={"CONTACT US"} title={"문의하기"} />
          <ContainerWrap>
            <h3>문의하기</h3>
            <div className="center">
              <div className="input_form">
                <div className="input_wrap">
                  <h2>이름</h2>
                  <StyledTextField
                    placeholder="성명을 입력해주세요."
                    id="outlined-basic"
                    variant="outlined"
                    value={formData.name}
                    name="name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_wrap">
                  <h2>연락처</h2>
                  <StyledTextField
                    placeholder="숫자만 입력해주세요."
                    id="outlined-basic"
                    variant="outlined"
                    name="contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_wrap">
                  <h2>이메일</h2>
                  <StyledTextField
                    placeholder="email@email.com"
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_wrap">
                  <h2>제목</h2>
                  <StyledTextField
                    placeholder="제목을 입력해주세요."
                    id="outlined-basic"
                    variant="outlined"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_wrap textarea_wrap">
                  <h2>문의내용</h2>
                  <div className="check_wrap">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={10}
                      fullWidth
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    <ul className="dp-flex">
                      <li>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="개인정보수집에 동의합니다"
                        />
                        <a href="">전문보기</a>
                      </li>
                      <li>
                        <Button variant="contained" onClick={handleClick}>
                          문의하기
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ContainerWrap>
        </NoticeWrap>
      </ThemeProvider>
    </>
  );
}

const ContainerWrap = styled(Box)`
  width: 128rem;
  margin: 0 auto;
  padding: 5rem 0;
  @media ${() => theme.device.desktop} {
    width: 90%;
  }
`;

const NoticeWrap = styled(Box)`
  h3 {
    color: #3b3b3b;
    font-size: 2rem;
    font-weight: bold;
    line-height: 150%;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid #00367b;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .input_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    padding: 3rem 0;
    .input_wrap {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      h2 {
        font-size: 2rem;
        color: #3b3b3b;
        font-weight: bold;
        width: 20rem;
        min-width: 20rem;
      }
      input {
        font-size: 1.6rem;
        width: 50%;
      }
      textarea {
        font-size: 1.6rem;
      }
      p {
        padding: 1rem;
        font-size: 2rem;
      }
    }
    .textarea_wrap {
      align-items: flex-start;
      .check_wrap {
        width: 100%;
        .dp-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2rem;
          li {
            display: flex;
            align-items: center;
          }
          span {
            font-size: 1.6rem;
          }
          a {
            color: #3676d2;
            text-decoration: underline;
            font-size: 1.6rem;
            font-weight: bold;
          }
          button {
            box-shadow: none;
            font-size: 1.8rem;
            padding: 1rem 5rem;
          }
        }
      }
      h2 {
        padding-top: 1rem;
      }
    }
  }

  @media ${() => theme.device.tablet} {
    .input_form {
      width: 100%;
    }
  }
  @media ${() => theme.device.mobile} {
    .input_form {
      .input_wrap {
        width: 100%;
        h2 {
          width: 12rem;
          min-width: 12rem;
          font-size: 1.8rem;
        }
        input {
          width: 100%;
        }
      }
      .textarea_wrap {
        flex-direction: column;
        .check_wrap {
          margin-top: 2rem;
          .dp-flex {
            flex-direction: column;
            button {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  @media ${() => theme.device.mobile} {
    width: 100%;
  }
`;
