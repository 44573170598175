import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { createTheme, Divider, Icon, ThemeProvider } from "@mui/material";
import { Box, IconButton, Button } from "@mui/material";
import theme from "../../style/theme";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import useStore from "../../common/store";
import Nav from "../../component/common/Nav";
import Banner from "../../component/notice/Banner";

export default function Notice() {
  const { getNotices, setNotice_id, _incrementViewCount } = useStore();
  const navigate = useNavigate();

  const [status, setStatus] = useState(1);
  const [page, setPage] = useState(1);
  const [total, setTotalPage] = useState(10);
  const [notices, setnotices] = useState([]);
  const [loading, setloading] = useState(true);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
    palette: {
      primary: {
        main: "#3676D2",
      },
    },
  });
  useEffect(() => {
    const admin = localStorage.getItem("user");
    setStatus(admin);
  }, [status]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNotices(page);
        setnotices(response.list);

        const total = parseInt(response.total);
        setTotalPage(total);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  const hadleClick = async (id, num) => {
    setNotice_id(id);
    const count = num + 1;
    const response = await _incrementViewCount(count, id, 1); // type 1: 공지사항, 2: faq
    navigate(`/notice/detail_noti`);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav select={"고객서비스"} />
        <NoticeWrap>
          <Banner menu={"NOTICE"} title={"공지사항"} />
          {loading === false ? (
            <ContainerWrap>
              <h3>공지사항</h3>

              <h2 className="test">현재 준비중입니다.</h2>
            </ContainerWrap>
          ) : (
            <ContainerWrap>
              <h3>공지사항</h3>
              <div className="scroll">
                <NoticeTable>
                  <Thead>
                    <div className="th w-1">번호</div>
                    <div className="th w-5">제목</div>
                    <div className="th w-3">첨부파일</div>
                    <div className="th w-3">작성일</div>
                    <div className="th w-2">조회수</div>
                  </Thead>
                  {notices.map((item, index) => {
                    if (item["important_status"] === true) {
                      return (
                        <Tbody
                          className="tbody"
                          key={index}
                          onClick={(e) =>
                            hadleClick(item["id"], item["number_of_views"])
                          }
                        >
                          <div className="tb w-1 important">주요공지</div>
                          <div className="tb w-5 important">
                            {item["title"]}
                          </div>
                          <div className="tb w-3 important">
                            {item["file_name"]}
                          </div>
                          <div className="tb w-3 important">
                            {item["creationdate"]}
                          </div>
                          <div className="tb w-2 important">
                            {item["number_of_views"]}
                          </div>
                        </Tbody>
                      );
                    } else {
                      return (
                        <Tbody
                          className="tbody"
                          key={index}
                          onClick={(e) =>
                            hadleClick(item["id"], item["number_of_views"])
                          }
                        >
                          <div className="tb w-1">{item["id"]}</div>
                          <div className="tb w-5">{item["title"]}</div>
                          <div className="tb w-3">{item["file_name"]}</div>
                          <div className="tb w-3">{item["creationdate"]}</div>
                          <div className="tb w-2">
                            {item["number_of_views"]}
                          </div>
                        </Tbody>
                      );
                    }
                  })}
                </NoticeTable>
              </div>
              <div
                className={
                  status !== true ? "pagination_wrap_ad" : "pagination_wrap"
                }
              >
                {status !== undefined && status !== null && status !== true && (
                  <div className="margin_add"></div>
                )}
                <Pagination
                  className="pagination"
                  count={total}
                  color="primary"
                  boundaryCount={1}
                  onChange={(e, value) => setPage(value)}
                />
                {status !== undefined && status !== null && status !== true && (
                  <button className="w-btn w-btn-blue">등록</button>
                )}
              </div>
            </ContainerWrap>
          )}
        </NoticeWrap>
      </ThemeProvider>
    </>
  );
}

const ContainerWrap = styled(Box)`
  width: 128rem;
  margin: 0 auto;
  padding: 5rem 0;
  @media ${() => theme.device.desktop} {
    width: 90%;
  }
`;

const NoticeWrap = styled(Box)`
  .test {
    text-align: center;
    color: #3b3b3b;
    font-size: 3rem;
    font-weight: bold;
    line-height: 150%;
    width: 100%;
    height: 20rem;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  h3 {
    color: #3b3b3b;
    font-size: 2rem;
    font-weight: bold;
    line-height: 150%;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid #00367b;
  }
  .pagination_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 0 0;
  }

  .pagination_wrap_ad {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 0 0 0;
  }

  button {
    font-size: 1.4rem;
  }
  .w-btn {
    position: relative;
    border: none;
    display: inline-block;
    padding: 10px 25px;
    font-weight: 600;
    transition: 0.25s;
  }
  .w-btn-blue {
    background-color: #a0a0a0;
    color: white;
  }
  .w-btn:hover {
    cursor: pointer;
  }

  .w-btn:active {
    background-color: #dcdcde;
    color: white;
  }

  .margin_add {
    padding: 10px 40px;
  }

  //   @media ${() => theme.device.desktop} {
  //     .scroll {
  //       overflow-y: scroll;
  //     }
  //   }
`;

const NoticeTable = styled(Box)`
  .w-1 {
    width: 10%;
  }
  .tbody {
    cursor: pointer;
  }
  .w-2 {
    width: 20%;
  }
  .w-3 {
    width: 30%;
  }
  .w-5 {
    width: 50%;
  }
  .w-6 {
    width: 60%;
  }
  @media ${() => theme.device.desktop} {
    width: 100%;
  }
`;

const Thead = styled(Box)`
  display: flex;
  background-color: #fafafb;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .th {
    padding: 1.5rem 0;
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
    font-weight: bold;
  }
`;

const Tbody = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .tb {
    padding: 1.5rem 0;
    background-color: #fff;
    font-size: 1.6rem;
    color: #3b3b3b;
    text-align: center;
  }
  .important {
    color: #3676d2;
    font-weight: bold;
  }
`;

const button = styled(Button)``;
